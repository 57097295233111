<template>
    <div class="orderSuccess centerWidth marginAuto">
        <div class="centerWidth flex flexColumn alignCenter">
            <div style="width: 74px;height: 74px;background: #10c45c;border-radius:50%;margin-top:42px;" class="flex alignCenter spacearound">
                <i class="el-icon-check" style="font-size:60px;color:#fff"></i>
            </div>
            <div class="ordermsg c33 fontW" style="font-size:16px;">
                订单已提交成功，请于30分钟内完成支付（逾期订单将被取消）
            </div>
            <div class="orderInfo c33" style="font-size:16px;">
                订单编号：{{orderNum}}
            </div>
            <div class="orderAddress c33 flex alignCenter">
                <i class="el-icon-location" style="color:#FF0036;font-size:25px;padding-left:17px;"></i>
                <span>送货至：</span>
                <div v-if="!$route.query.orderList">
                    <span>{{calladdressinfo.consignee_name}}</span>
                    <span>{{calladdressinfo.consignee_phone}}</span>
                    <span>{{calladdressinfo.provice}}</span>
                    <span>{{calladdressinfo.city}}</span>
                    <span>{{calladdressinfo.area}}</span>
                    <span>{{calladdressinfo.address}}</span>
                </div>
                <div v-else>
                    <span>{{calladdressinfo}}</span>
                </div>
            </div>
            <div class="payBtns centerWidth marginAuto flex alignCenter c33">
                <button class="wxpay" style="margin-left:30px;" @click="payMethod = 1">微信支付</button><button class="alipay" @click="payMethod = 2; initial()">支付宝支付</button>
                <span style="padding-left:50px;font-size:16px;">应付款：</span> <span style="font-size:27px;color:#FF0036;">{{showprice}}</span> <span style="padding-left:20px;font-size:16px;">（在线支付）</span>
            </div>
            <div class="payType centerWidth marginAuto flex spacearound">
                <div class="payCenter flex spacebetween">
                    <img src="@/assets/img/pay/wxpay.png" style="width:273px;height:200px;border-right:1px dashed #DFDFDF" alt="">
                    <div class="flex flexColumn spacearound alignCenter">
                        使用微信扫一扫支付
                        <div class="qrcode" ref="qrCodeUrl"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { goods_order_pc } from '@/utils/Api/shop'
import { getToken } from '@/utils/auth'
import QRCode from 'qrcodejs2'
import { order_detail } from '@/utils/Api/order'
export default {
    name:'orderSuccess',
    data(){
        return{
            orderNum:'',
            showprice:'',
            payMethod: 1,
            referInterval: null,
            calladdressinfo:{}
        }
    },
    created(){
        if(!this.$route.query.orderList){
            this.showprice = JSON.parse(getToken('price'))
        }
        this.orderNum = this.$route.query.orderNum
        this.initial(this.payMethod)
        this.referOrder()
    },
    beforeDestroy(){
        clearInterval(this.referInterval)
        this.referInterval = null
    },
    methods:{
        /* 查询订单是否支付完成 */
        referOrder(){
            this.referInterval = setInterval(() => {
                order_detail({order_sn: this.orderNum}).then(res => {
                    if(res.status != 0){
                        this.$message.success('支付成功！')
                        clearInterval(this.referInterval)
                        setTimeout(()=>{
                            this.goother({path:'/goodslist/paysuccess'})
                        },1000)
                    }
                })
            },1500)
        },
        async initial(){
            let res = await goods_order_pc(
                {
                    order_num: this.orderNum,
                    pay_method: this.payMethod
                }
            )
            if(res && this.payMethod == 1){
                this.qr(res)
            }
            if(res && this.payMethod == 2){
                const div = document.createElement('payHtml');
                div.innerHTML = res
                document.body.appendChild(div);
                setTimeout(() => {
                    document.forms['alipaysubmit'].submit() 
                })
            }
            if(this.$route.query.orderList){
                order_detail({order_sn: this.orderNum}).then(orderRes => {
                    console.log(orderRes)
                    this.calladdressinfo = orderRes.address

                    let integ =0, price = 0
                    orderRes.goods_order.forEach((im,ix) => {
                        console.log(im,ix)
                        integ += parseFloat(im.integral) * im.num
                        price += parseFloat(im.price) * im.num
                    });
                    this.showprice = `${integ.toFixed(2)}积分 + ￥${(price+parseFloat(orderRes.express)).toFixed(2)}`
                })
                // this.calladdressinfo = {
                //     provice: res.
                // }
            }else{
                this.calladdressinfo = this.addressinfo
            }
        },
        qr(value){
            this.$nextTick(() => {
                new QRCode(this.$refs.qrCodeUrl, {
                    text: value.data, // 需要转换为二维码的内容
                    width: 130,
                    height: 130,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            })
        }
    },
    computed:{
        addressinfo(){
            let info = {}
            this.$store.getters.addressList.forEach(im => {
                if(im.id == this.$route.query.addressId){
                    info = im
                }
            });
            return info
        }
    },
    components:{QRCode}
}
</script>
<style lang="scss" scoped>
.payType{
    margin-top: 33px;
    height: 200px;
    background: #fff;
    .payCenter{
        width: 550px;
    }
}
.payBtns{
    margin-top: 40px;
    height: 50px;
    button{
        width: 97px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
        margin-left: 18px;
    }
    .wxpay{
        background: #1ec56b;
        color: #fff;
    }
    .alipay{
        background: none;
        border: 1px solid #0091FF;
        color: #0091FF;
    }
}
.orderAddress{
    width: 1150px;
    height: 67px;
    opacity: 1;
    background: #fff9f5;
    font-size: 16px;
    margin-top: 33px;
    span{
        font-size: 16px;
        font-weight: bold;
        color: #333;
        padding-left: 20px;
    }
}
.orderSuccess{
    height: 670px;
    border: 1px solid #dfdfdf;
    margin-bottom: 30px;
    .orderInfo{
        margin-top: 15px;
    }
    .ordermsg{
        margin-top: 27px;
    }
}
</style>